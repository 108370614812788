$theme-colors: (
        "primary": #bfa2bf,
        "secondary": #75a1b7,
);

$colors: (
        "lila": #bfa2bf,
);


@import "~bootstrap/scss/bootstrap";

$logoSize: 8rem;



.Logo {
  height: $logoSize;
  width: auto;
}

.Abo {
  margin: 1rem 0 0 0;
  &-Heading {
    font-size: 1.5rem;
    font-weight: 600;
  }
  &-SubHeading {
    margin: -0.4rem 0 0 0;
    font-size: 1.1rem;
    font-weight: 400;
    color: lighten(#000, 50%);
  }
}

.SepaData {
  overflow: hidden;
  height: 0;
  //max-height: 0;
  transition: height 0.5s ease-in-out;

  &-Active {
    height: 180px;
    //max-height: 100%;
  }
}

.list-group-item-check:checked + .list-group-item {
  color: #fff;
  background-color: var(--bs-lila);
}
